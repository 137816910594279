import React from 'react';
import Layout from '../components/Layout';
import Rodolfo from '../images/Rodolfo-Main.png'
import RodolfoJarumahum from '../images/Rodolfo-Jarumahum-Jr.jpg'
import JasperTanamal from '../images/Jasper-Tanamal.jpg'
import CrystalMaeJarumahum from '../images/Crystal-Mae-Jarumahum.png'
import MichealAdger from '../images/Micheal-Adger.jpg'
import RubyAdger from '../images/Ruby-Adger.jpg'

const rowPadding = {
  padding: "150px 0"
}

export default function OurTeams() {
  return (
    <Layout>
      <section id='our-team' style={{marginTop: '74px'}}>
        
        <div className='container'>
          <div className='row align-items-center' style={{ paddingTop: '50px' }}>
            <div className='col-md-5'>
              <img src={Rodolfo} alt='Rodolfo Jarumahum Jr.' />
            </div>

            <div className='col-md-7 text-white'>
              <p className='fs-5'>I aim to help business owners and individuals to create their online presence. I understand that they are hesitant to get online because of the price and they feel that they are not tech-savvy enough to manage a website. That is why we at GoogolWeb provide the best solutions that will work for you.</p>
              <p className='h5'>Rodolfo Jarumahum Jr.</p>
              <small>- Founder & Web Designer</small>
            </div>
          </div>
        </div>

        <div className='container-fluid'>
          <div className='row'>
            <div className='dot-pattern' style={{ backgroundRepeat: "round", backgroundSize: "cover" }}>
            <div className='container text-dark'>
              <div className='row g-5' style={rowPadding}>
                <h1 className='display-5 mt-0 text-center'>Our Amazing Team</h1>
                <div className='col-md-4'>
                  <img src={RodolfoJarumahum} className="img-fluid shadow w-100" alt="Rodolfo Jarumahum Jr" />
                  <div className='bg-white position-relative shadow text-center mx-4' style={{ marginTop: "-50px", padding: "35px 30px" }}>
                    <h6 className="mb-2 text-uppercase">Rodolfo Jarumahum Jr.</h6>
                    <p className="mb-2 text-muted"><small>- Founder & Web Designer</small></p>
                  </div>
                </div>

                <div className='col-md-4'>
                  <img src={JasperTanamal} className="img-fluid shadow w-100" alt="Jasper Tanamal" />
                  <div className='bg-white position-relative shadow text-center mx-4' style={{ marginTop: "-50px", padding: "35px 30px" }}>
                    <h6 className="mb-2 text-uppercase">Jasper Tanamal</h6>
                    <p className="mb-2 text-muted"><small>- Co Founder & Full Stack Web Developer</small></p>
                  </div>
                </div>

                <div className='col-md-4'>
                  <img src={CrystalMaeJarumahum} className="img-fluid shadow w-100" alt="Crystal Mae Jarumahum" />
                  <div className='bg-white position-relative shadow text-center mx-4' style={{ marginTop: "-50px", padding: "35px 30px" }}>
                    <h6 className="mb-2 text-uppercase">Crystal Mae Jarumahum</h6>
                    <p className="mb-2 text-muted"><small>- Chief Marketing Officer</small></p>
                  </div>
                </div>

                <div className='col-md-4'>
                  <img src={MichealAdger} className="img-fluid shadow w-100" alt="Micheal Adger" />
                  <div className='bg-white position-relative shadow text-center mx-4' style={{ marginTop: "-50px", padding: "35px 30px" }}>
                    <h6 className="mb-2 text-uppercase">Micheal Adger</h6>
                    <p className="mb-2 text-muted"><small>- NZ Marketing Officer</small></p>
                  </div>
                </div>

                <div className='col-md-4'>
                  <img src={RubyAdger} className="img-fluid shadow w-100" alt="Micheal Adger" />
                  <div className='bg-white position-relative shadow text-center mx-4' style={{ marginTop: "-50px", padding: "35px 30px" }}>
                    <h6 className="mb-2 text-uppercase">Ruby Adger</h6>
                    <p className="mb-2 text-muted"><small>- Managing Director & Chief Accounting Officer</small></p>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
